<template>
    <div class="loading-curtain active">
        <div class="container">
			<img src="/images/logo.png" alt="">
			<b v-if="error" class="notice">{{ error }}</b>
		</div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { hasCompletedSignup } from '@/utils/verify-signup-status';

export default {

	name: 'SocialProviderReturnPage',

	props: [ 'userType', 'driver' ],

	data() {
		return {
			loading: true,
			error: '',
		};
	},

	computed: {

		...mapGetters([ 'hasIntended', 'intendedRoute' ]),

	},

	mounted() {
		this.authenticate(this.userType, this.driver, this.$route.query);
	},

	methods: {

		async authenticate(userType, driver, params) {
			let url = `/login/${ userType }/${ driver }/callback`;

			try {
				const response = await this.$api.get(url, { params });

				const authHeader = response.headers['x-user'];

				if (authHeader) {
					const auth = JSON.parse(atob(authHeader));
					switch (userType) {
						case 'business':
							return this.$nextTick(() => {
								if (!auth.user || !auth.user.email) {
									if (!hasCompletedSignup(this.$store)) {
										return this.$router.push('/signup/business/info');
									}
									this.$router.push(`/signup/business/info?new=${!!auth.new}`);
								} else if (auth.new) {
									// This is a new account. Let's go to the welcome page that'll also ensure we load all their locations & items
									this.$router.push('/business/welcome');
								}

								return this.$router.push('/business/locations');
							});
						case 'customer':
						default:
							return this.$nextTick(() => {
								if (!hasCompletedSignup(this.$store)) {
									return this.$router.push('/signup/customer/info');
								}
								if (!auth.user || (!auth.user.email || !auth.user.first_name || !auth.user.last_name)) {
									this.$router.push('/signup/customer/info');
								} else {
									this.$store.dispatch('goIntended', userType === 'customer' ? '/browse/list' : '/business/locations');
								}
							});

					}
				}

				this.handleAuthFailure();

			} catch (e) {
				this.handleAuthFailure();
			}
		},

		handleAuthFailure() {
			this.$store.dispatch('errorToast', 'Unable to authenticate you');
			this.$store.dispatch('logout');
			this.$router.push('/login');
		},

	}
};

</script>
